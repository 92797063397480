import React, { useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import { navigate } from "gatsby"
import { Form } from "../style/globalStyles"
import Moment from "moment"

import Switch from "react-switch"
import { updateDoctor } from "../state"
export const SingleDoctor = ({ doctorData = {} }) => {
  useEffect(() => {
    doctorData === null && history.go(-1)
  }, [])
  const days = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ]
  // console.log(doctorData.scheduleBean)
  var descDay

  var scheduleLookup = days
    .map((day) => {
      // console.log(day)
      descDay = day + "ScheduleDesc"
      // console.log(doctorData.scheduleBean[descDay])
      return {
        day,
        scheduleArray:
          doctorData.scheduleBean &&
          doctorData.scheduleBean[descDay].filter((lookUp) => lookUp !== null),
      }
    })
    .map((Day) => {
      // console.log(Day.day)
      // console.log(Day.scheduleArray)
      return {
        day: Day.day,
        schTime:
          Day.scheduleArray &&
          Day.scheduleArray.map((schLookup) => {
            // console.log(schLookup.lookupDescription)
            return schLookup && schLookup.lookupDescription
          }),
      }
    })
  // console.log(scheduleLookup)
  var ScheduleLookup = scheduleLookup.map((lookup, index) => {
    // console.log(parseInt(lookup.schTime[0]))
    return (
      lookup.schTime &&
      lookup.schTime[0] !== null && (
        <div key={index}>
          <h5 style={{ padding: "1rem 0", color: "gray" }}>{lookup.day}</h5>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {lookup.schTime.length !== 0 ? (
              lookup.schTime
                .sort(function (a, b) {
                  return parseInt(a) - parseInt(b)
                })
                .map((sT, index) => {
                  return (
                    <p
                      key={index}
                      style={{
                        margin: "0.5rem 0.5rem 0.5rem 0",
                        lineHeight: "2rem",
                        fontSize: ".9rem",
                        borderRadius: "5%",
                        padding: ".1rem .7rem",
                        // color: "white",
                        backgroundColor:
                          parseInt(sT) <= 11 ? "lightsalmon" : "lightskyblue",
                      }}
                    >
                      {sT}
                    </p>
                  )
                })
            ) : (
              <p style={{ fontSize: "0.8rem" }}>Schedule not available</p>
            )}
          </div>
          <br />
        </div>
      )
    )
  })
  const [state, setState] = useState({ ...doctorData })
  const [enable, setEnable] = useState(false)
  const dispatch = useDispatch()
  // useEffect(() => {
  //   if (doctorData) {
  //     setState(doctorData)
  //   }

  //   console.log("useEffect state", state)
  // }, [doctorData])
  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value })
    setEnable(true)
  }
  const handleSubscribeChange = (value) => {
    setState({ ...state, isSubscription: value })
    setEnable(true)
  }

  const handleActiveChange = (value) => {
    if (state.isVerified === false) {
      alert("Please verify the doctor to activate")
      return
    }
    setState({ ...state, isActive: value })
    // setEnable(true)
  }
  const handleVerifiedChange = (value) => {
    // setState({ ...state, isActive: value })
    dispatch(updateDoctor({ ...state, isVerified: value ? 1 : 0 }))

    setState({ ...state, isVerified: value ? 1 : 0 })
    // setEnable(true)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setEnable(false)
    alert("Doctor approval status has been updated")
    navigate("/doctors")
  }

  return (
    <>
      <Form onSubmit={handleSubmit} style={{}}>
        <div style={{ paddingRight: "1rem" }}>
          <div
            style={{
              marginBottom: "2rem",
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              width: "100%",
              // paddingBottom: "1rem",
              borderBottom: "1px solid lightgray",
            }}
          >
            <label htmlFor="firstname">
              First Name
              <p>{doctorData.firstName}</p>
              {/* <input
              id="firstname"
              name="firstName"
              type="text"
              placeholder={doctorData.firstName}
              onChange={handleChange}
            /> */}
            </label>
            <label>
              Last Name
              <p>{doctorData.lastName || "NA"}</p>
            </label>
            <label htmlFor="dob">
              Date of Birth
              <p>
                {doctorData.dob
                  ? Moment(doctorData.dob).format("DD MMM YYYY")
                  : "NA"}
              </p>
            </label>
            <label htmlFor="genderDesc">
              Gender
              <p>{doctorData.genderDesc || "NA"}</p>
            </label>
            <label htmlFor="mobileNo">
              Mobile Number
              <p>{doctorData.mobileNo || "NA"}</p>
            </label>
            <label htmlFor="email">
              Email
              <p>{doctorData.email || "NA"}</p>
            </label>
            <p />
          </div>
          <div
            style={{
              marginBottom: "2rem",
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              borderBottom: "1px solid lightgray",
              width: "100%",
            }}
          >
            <label htmlFor="doctorId">
              Doctor ID
              <p>{doctorData.doctorId || "NA"}</p>
            </label>
            <label htmlFor="experience">
              Experience
              <p>{doctorData.experience || 0}</p>
            </label>
            <label htmlFor="imcRegistration">
              IMC Registration
              <p>{doctorData.imcRegistrationNo || "NA"}</p>
            </label>
            <label htmlFor="imcRegistration">
              University
              <p>{doctorData.university ? doctorData.university : "NA"}</p>
            </label>
            <label htmlFor="speciality">
              Speciality
              <p>{doctorData.speciality || "NA"}</p>
            </label>
            <label htmlFor="specialityDesc">
              Speciality Description
              <p>{doctorData.specialityDesc || "NA"}</p>
            </label>

            <label>
              Languages Spoken
              <p>
                {doctorData.languageLookupMap
                  ? doctorData.languageLookupMap
                      .filter((lookUp) => lookUp !== null)
                      .map((lookUp) => {
                        return lookUp.lookupDescription
                      })
                      .join(", ")
                  : "NA"}
              </p>
            </label>
          </div>
          <div
            style={{
              marginBottom: "2rem",
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              borderBottom: "1px solid lightgray",

              width: "100%",
            }}
          >
            <label htmlFor="addressLine1">
              Address Line 1
              <p>{doctorData.addressLine1 ? doctorData.addressLine1 : "NA"}</p>
            </label>
            <label htmlFor="addressLine2">
              Address Line 2
              <p>{doctorData.addressLine2 ? doctorData.addressLine2 : "NA"}</p>
            </label>
            <label htmlFor="city">
              City
              <p>{doctorData.city ? doctorData.city : "NA"}</p>
            </label>
            <label htmlFor="state">
              State
              <p>{doctorData.state ? doctorData.state : "NA"}</p>
            </label>
            <label htmlFor="pincode">
              Pincode
              <p>{doctorData.pinCode ? doctorData.pinCode : "NA"}</p>
            </label>
            <label htmlFor="country">
              Country
              <p>{doctorData.country ? doctorData.country : "NA"}</p>
            </label>
          </div>
          <div
            style={{
              marginBottom: "2rem",
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              width: "100%",
              borderBottom: "1px solid lightgray",
            }}
          >
            <label htmlFor="clinicName">
              Clinic Name
              <p> {doctorData.clinic ? doctorData.clinic.clinicName : "NA"}</p>
            </label>
            <label htmlFor="clinicPhoneNo">
              Phone No
              <p>
                {doctorData.clinic ? doctorData.clinic.clinicPhoneNo : "NA"}
              </p>
            </label>
            <label htmlFor="clinicEmail">
              Email
              <p>{doctorData.clinic ? doctorData.clinic.clinicEmail : "NA"}</p>
            </label>
            <label htmlFor="clinicAddress">
              Address
              <p>
                {" "}
                {doctorData.clinic ? doctorData.clinic.clinicAddress : "NA"}
              </p>
            </label>
            <label htmlFor="clinicCity">
              City
              <p> {doctorData.clinic ? doctorData.clinic.clinicCity : "NA"}</p>
            </label>
            <label htmlFor="clinicState">
              State
              <p>{doctorData.clinic ? doctorData.clinic.clinicState : "NA"}</p>
            </label>
            <label htmlFor="clinicCountry">
              Country
              <p>
                {doctorData.clinic ? doctorData.clinic.clinicCountry : "NA"}
              </p>
            </label>
            <label htmlFor="clinicPincode">
              Pincode
              <p>
                {doctorData.clinic ? doctorData.clinic.clinicPincode : "NA"}
              </p>
            </label>

            <p />
          </div>
          <div
            style={{ marginTop: "1rem", borderBottom: "1px solid lightgray" }}
          >
            <br />
            <h3 style={{ marginBottom: "1rem" }}>
              Availability of Slots{" "}
              <span style={{ fontWeight: "lighter", color: "gray" }}>
                (24 Hour)
              </span>
            </h3>
            {scheduleLookup && ScheduleLookup}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            width: "100%",
            height: "fit-content",
          }}
        >
          <label htmlFor="registrationCertificate">
            Regs. Certificate
            <br />
            <br />
            <a target="_blank" href={doctorData.registrationCertificate}>
              View
            </a>
          </label>
          <label htmlFor="educationalCertificate">
            Edu. Certificate
            <br />
            <br />
            <a target="_blank" href={doctorData.educationalCertificate}>
              View
            </a>
          </label>
          <label htmlFor="isActive">
            Active Status
            <br />
            {/* <Switch
            name="isActive"
            className="react-switch"
            onChange={handleActiveChange}
            checked={state.isActive}
          /> */}
            <p>{doctorData.isActive ? "Active   " : "Not Active    "}</p>
          </label>

          <label htmlFor="isVerified">
            Verified Status
            <br />
            <br />
            <Switch
              name="isVerified"
              className="react-switch"
              onChange={handleVerifiedChange}
              checked={state.isVerified}
            />
          </label>

          <label htmlFor="isSubscription">
            Subscription Status
            <br />
            <p>
              {doctorData.isSubscription ? "Subscribed" : "No Subscription"}
            </p>
            {/* <Switch
            name="isSubscription"
            className="react-switch"
            onChange={handleSubscribeChange}
            checked={state.isSubscription}
          /> */}
          </label>
          <label htmlFor="mobileNo">
            Created
            <p>
              {doctorData.created &&
                Moment(doctorData.created).format("DD MMM YYYY")}
            </p>
          </label>
          <label htmlFor="mobileNo">
            Last updated
            <p>
              {doctorData.updated &&
                Moment(doctorData.updated).format("DD MMM YYYY")}
            </p>
          </label>
          <br />
          <button
            type="submit"
            aria-disabled={enable ? true : false}
            style={{
              borderRadius: "5px",
              backgroundColor: enable ? "green" : "lightgray",
              cursor: enable ? "pointer" : "default",
            }}
          >
            Save Changes
          </button>
        </div>
      </Form>
      {/* <div style={{ marginTop: "1rem" }}>
        <br />
        <h3 style={{ marginBottom: "1rem" }}>
          Availability of Slots{" "}
          <span style={{ fontWeight: "lighter", color: "gray" }}>
            (24 Hour)
          </span>
        </h3>
        {scheduleLookup && ScheduleLookup}
      </div> */}
    </>
  )
}
