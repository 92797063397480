import { navigate } from "gatsby"
import React from "react"
import { Layout } from "../../components/Layout"
import { SingleDoctor } from "../../components/SingleDoctor"
import { DoctorsSection } from "../../style/doctors"

function Doctor({ location = {} }) {
  if (location.state === null) {
    // console.log(location.state)
    navigate("/doctors")
  }

  return (
    <div>
      <Layout>
        <DoctorsSection>
          <div style={{ display: "flex", width: "100%", alignItems: "center" }}>
            <p
              onClick={() => {
                navigate("/doctors")
                // ;((location.state.data.isPrimary === true ||
                //   location.state.data.isAgent === true) &&
                //   typeof history !== "undefined" &&
                //   history.go(-1)) ||
                //   (typeof history !== "undefined" && history.go(-2))
              }}
              style={{
                cursor: "pointer",
                // textDecoration: "underline",
                fontSize: "0.7rem",
                marginBottom: "1rem",
              }}
            >
              Doctors
            </p>
            <p
              style={{
                // cursor: "pointer",
                fontSize: "0.9rem",
                marginBottom: ".9rem",
                marginLeft: "1rem",
                marginRight: "1rem",
              }}
            >
              {" "}
              /{/* &#8827; */}
            </p>
            {/* {typeof location.state !== `undefined` &&
              location.state.data.isPrimary === undefined && (
                <>
                  <p
                    onClick={() => {
                      typeof history !== "undefined" && history.go(-1)
                    }}
                    style={{
                      cursor: "pointer",
                      textDecoration: "underline",
                      fontSize: "0.7rem",
                      marginBottom: "1rem",
                    }}
                  >
                    
                  </p>
                  <p
                    style={{
                      // cursor: "pointer",
                      fontSize: "0.9rem",
                      marginBottom: ".9rem",
                      marginLeft: "1rem",
                      marginRight: "1rem",
                    }}
                  >
                    {" "}
                    &#8827;
                  </p>
                </>
              )} */}
            <p
              style={{
                // cursor: "pointer",
                fontSize: "0.7rem",
                marginBottom: "1rem",
              }}
            >
              Doctor ID: {location.state ? location.state.data.doctorId : " "}
            </p>
          </div>
          <h1>
            doctor - {location.state ? location.state.data.firstName : " "}
          </h1>
          <SingleDoctor doctorData={location.state && location.state.data} />
        </DoctorsSection>
      </Layout>
    </div>
  )
}

export default Doctor
